import moment from "moment";

const DATE_FORMAT = {
  sheet: "DD/MM/YYYY",
  db: "YYYY-MM-DD",
};

// if the value is not parsable as a number, returns null
const parseFloatNullable = (toParse) => {
  const parsed = Number.parseFloat(toParse);
  return Number.isNaN(parsed) ? null : parsed;
};

/**
 * extracts data that matches the regex pattern with at least a single capture
 * returns it sorted by the captured regex, and trimmed to the number of subsystems
 */
const extractDataForSubsystems = (subsystems, sysRawData, pattern) => {
  const length = subsystems.length;
  if (!length) {
    return null;
  }
  return Object.entries(sysRawData)
    .reduce((acc, [fieldName, fieldData]) => {
      const match = RegExp(pattern).exec(fieldName);
      // gsheet used 1-based indexing, so the length check is correct
      if (match && match[1] <= length) {
        acc.push({ value: fieldData, idx: match[1] });
      }
      return acc;
    }, [])
    .sort((a, b) => a.idx - b.idx)
    .map((elem) => elem.value);
};

// all fields with enum comment should be validated with appropriate final DB values
function sheetToDbConstructor(sys, sysRawData) {
  const dateAsMoment = moment(sysRawData.date, DATE_FORMAT.sheet);
  if (!dateAsMoment.isValid()) {
    throw new Error("failed loading data: invalid date format");
  }

  const subsystems = sys.inverters || [];

  // removed so an empty value would not override an existing one
  // this["daily-sys-power"] = {};

  this.dailyuptime = parseFloatNullable(sysRawData.dailyuptime);
  this.dailyexpuptime = parseFloatNullable(sysRawData.daily_exp_uptime);
  this.dailyexpuptimeratio = parseFloatNullable(
    sysRawData.daily_exp_uptime_ratio
  );

  this.systemId = sys.id;
  this.date = dateAsMoment.format(DATE_FORMAT.db);
  this.severity = parseFloatNullable(sysRawData.severity);
  this.region_rating = parseFloatNullable(sysRawData.regionrating);
  this.syshealthpct = parseFloatNullable(sysRawData.syshealthpct);
  this.drevenue = parseFloatNullable(sysRawData.drevenue);
  this.drevloss = parseFloatNullable(sysRawData.drevloss);

  this.depotential = parseFloatNullable(sysRawData.depotential);
  this.expdaily = parseFloatNullable(sysRawData.expdaily);
  this.dailyratio = parseFloatNullable(sysRawData.dailyratio);
  this.yearloss = parseFloatNullable(sysRawData.yearloss);
  this.yepotential = parseFloatNullable(sysRawData.yepotential);
  this.if_pow = extractDataForSubsystems(
    subsystems,
    sysRawData,
    /^if-pow-(\d+)$/
  );
  this.if_latestart = extractDataForSubsystems(
    subsystems,
    sysRawData,
    /^if-latestart-(\d+)$/
  );
  this.if_powdeg = extractDataForSubsystems(
    subsystems,
    sysRawData,
    /^if-powdeg-(\d+)$/
  );
  this.if_inv_n = extractDataForSubsystems(
    subsystems,
    sysRawData,
    /^if-inv-(\d+)$/
  );
  // this.if_invdeg = extractDataForSubsystems(
  //   subsystems,
  //   sysRawData,
  //   /^if-invdeg-(\d+)$/
  // );
  this.ef_message = sysRawData["ef-message"]; // NOT an enum, free form string
  // this.ef_logger = sysRawData["ef-webbox"]; // NOT an enum, free form string
  this.f_comm = sysRawData["f-comm"]; // enum
  this.f_erange = sysRawData["f-erange"]; // enum
  this.f_prange = sysRawData["f-prange"]; // enum
  this.f_vgrid = sysRawData["f-vgrid"]; // enum
  this.f_vgridshift = sysRawData["f-vgridshift"]; // enum
  this.f_dust = sysRawData["f-dust"]; // enum
  this.f_mcm = sysRawData["f-mcm"]; // enum
  this.f_fct = sysRawData["f-fct"]; // enum
  this.f_ovld = sysRawData["f-ovld"]; // enum
  this.f_intres = sysRawData["f-intres"]; // enum
  this.f_shadow = sysRawData["f-shadow"]; // enum

  // defined only in case an actual value is returned so that an empty value would not override an existing one
  const denoutput = parseFloatNullable(sysRawData.denoutput);
  if (denoutput !== null) {
    this.denoutput = denoutput;
  }
}

export default (tabData, systems) => {
  return Object.fromEntries(
    Object.entries(tabData).map(([sysId, sysRawData]) => {
      return [
        sysId,
        { ...new sheetToDbConstructor(systems[sysId], sysRawData) },
      ];
    })
  );
};
